<template>
  <Header />
  <Principal />
  <Social />
  <Footer />
</template>

<script>
import Header from "./components/Header.vue";
import Social from "./components/Social.vue";
import Principal from "./views/Principal.vue"
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Social,
    Footer,
    Principal
  },
};
</script>

<style>
#app {
  font-family: "Titillium Web", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--greyspdark);
}
h1 {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}
h2 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
h3 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.body1 {
  font-size: 10px;
  font-weight: normal;
  color: #262424;
}
.body2 {
  font-size: 16px;
  font-weight: lighter;
  color: #262424;
}
.caption {
  font-size: 16px;
  font-weight: lighter;
}
.btn-font {
  font-size: 21px;
  font-weight: bold;
}
@media (min-width: 768px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  .body1 {
    font-size: 16px;
  }
  .body2 {
    font-size: 24px;
  }
}
</style>
