<template>
  <div class="social">
    <ul>
      <li>
        <ShareNetwork
          network="twitter"
          twitter-user="SerendipiaData"
          url="https://victimas-paralelas.serendipia.digital/"
          title="Víctimas Paralelas: las afectadas de las que no se habla"
          description="Desde la aparición del SARS-CoV-2, en México se han reportado aproximadamente 324 mil muertes causadas por la enfermedad COVID-19. "
          hashtags="Víctimas Paralelas"
          ><img src="../assets/twitter-icon-min.svg" alt="Twitter"
        /></ShareNetwork>
      </li>
      <li>
        <ShareNetwork
          network="facebook"
          url="https://victimas-paralelas.serendipia.digital/"
          title="Víctimas Paralelas: las afectadas de las que no se habla"
          description="Desde la aparición del SARS-CoV-2, en México se han reportado aproximadamente 324 mil muertes causadas por la enfermedad COVID-19. "
          quote="Desde la aparición del SARS-CoV-2, en México se han reportado aproximadamente 324 mil muertes causadas por la enfermedad COVID-19. Sin embargo, otro tipo de padecimientos continuaron cobrando vidas humanas sin que las autoridades prestaran especial atención"
          hashtags="Víctimas Paralelas"
        >
          <img src="../assets/Facebook-icon-min.svg" alt="Facebook logo" />
        </ShareNetwork>
      </li>
      <li>
        <ShareNetwork
          network="whatsapp"
          url="https://victimas-paralelas.serendipia.digital/"
          title="Víctimas Paralelas: las afectadas de las que no se habla"
          description="Desde la aparición del SARS-CoV-2, en México se han reportado aproximadamente 324 mil muertes causadas por la enfermedad COVID-19. "
          ><img src="../assets/whatsapp-icon-min.svg" alt="whatsapp"
        /></ShareNetwork>
      </li>
      <li>
        <ShareNetwork
          network="email"
          url="https://victimas-paralelas.serendipia.digital/"
          title="Víctimas Paralelas: las afectadas de las que no se habla"
          description="Desde la aparición del SARS-CoV-2, en México se han reportado aproximadamente 324 mil muertes causadas por la enfermedad COVID-19. "
          ><img src="../assets/mail-icon-min.svg" alt="Email"
        /></ShareNetwork>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style>
.social {
  position: fixed;
  top: 30%;
  left: 0;
  background-color: #992929;
  border-radius: 0 10px 10px 0;
  z-index: 1000;
}

.social ul {
  padding: 2px 3px 2px 5px;
  margin: 0;
}
.social li {
  list-style: none;
  margin: 10px 0px;
}
.social img {
  width: 30px;
}
.sp_ficon {
  background-repeat: no-repeat;
  border: 0;
  overflow: hidden;
  background-color: transparent;
  display: block;
  height: 30px;
  width: 35px;
}
</style>