<template>
  <div class="card">
    <a :href="info.url">
      <img
        :src="info.img"
        class="card-img-top"
        alt="dinero para la Alerta de Género en Chiapas"
      />
      <div class="card-body">
        <div class="card-head row">
          <div class="head-icon col-2">
            <div class="head-icon-container">
              <img
                v-if="source == 'Serendipia'"
                src="../assets/Serendipia.svg"
              />
              <img v-else src="../assets/Poder.svg" />
            </div>
          </div>
          <div class="head-content col-10">
            <span class="row autor">{{ info.autor }} · {{ source }}</span
            ><span class="row date">{{ info.date }}</span>
          </div>
        </div>
        <h3 class="card-title">
          {{ info.titulo }}
        </h3>
        <p class="card-text">
          {{ info.text }}
        </p>
        <a :href="info.url" class="btn card-link"> LEE MÁS SOBRE EL TEMA </a>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
    source: String,
  },
  name: "Card",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s;
}
.card:hover {
  transform: scale(1.05);
}
.card a {
  text-decoration: none;
}
.card .head-content .autor {
  color: #1d1b1b;
}
.card .head-content .date {
  color: #858585;
}
.card .card-title {
  font-size: 1.5rem;
  color: #4e4e4e;
  font-weight: bolder;
}
.card .card-text {
  color: #333333;
  margin-bottom: 1rem;
}
.card .card-link {
  background-color: #992929;
  color: #fff;
}

</style>
