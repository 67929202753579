<template>
  <div class="container mt-5">
    <!-- texto inicial -->
    <div class="row text-center">
      <!-- <div class="col-10 offset-md-1"> -->
      <div>
        <h1>Víctimas Paralelas</h1>
        <h2 class="mb-5">Un proyecto de PODER y Serendipia</h2>
        <p align="left" class="fs-5">
          Desde la aparición del SARS-CoV-2, en México se han reportado
          aproximadamente 324 mil muertes causadas por la enfermedad COVID-19.
          Sin embargo, otro tipo de padecimientos como el cáncer, el VIH o la
          diabetes continuaron cobrando vidas humanas sin que las autoridades
          prestaran especial atención a los repetidos casos de desabasto, falta
          de equipo médico, incapacidad para brindar las consultas necesarias o
          compras públicas que no correspondían a los informes federales de
          gasto.
        </p>
        <p align="left" class="fs-5">
          En ese contexto, en Serendipia y PODER Latam nos dimos a la tarea de
          registrar los casos más relevantes que tomaran como ejemplo algunos
          problemas en la atención al sector salud, mismos que tuvieron como
          consecuencia el agravio a la atención digna, acceso universal y
          atención humanitaria para todas aquellas personas que vieron mermada
          la atención médica durante la crisis sanitaria y que sus necesidades
          eran ajenas al contexto de la pandemia.
        </p>
        <p align="left" class="fs-5">
          En total, Víctimas Paralelas contempla 12 reportajes mensuales
          comenzando en noviembre 2021 y se extiende al mes de noviembre 2022.
          Presentando, de esta manera, un reportaje de investigación con datos
          abiertos por mes y por cada organización (PODER/SERENDIPIA).
        </p>
      </div>
    </div>
    <!-- cards -->
    <div class="container-fluid mt-5">
      <!-- main -->
      <div class="container mainNote mt-5 mb-5 pb-5">
        <a :href="mainNote.url">
          <div class="row">
            <div class="col-md-6 imgCol p-0">
              <img class="mainImage" :src="mainNote.img" alt="vih" />
            </div>
            <div class="col-md-6 textCol">
              <h3 class="title">{{ mainNote.titulo }}</h3>
              <span class="date">{{ mainNote.date }}</span>
              <p>
                {{ mainNote.text }}
              </p>
              <div class="card-head row mb-2">
                <div class="head-icon col-2">
                  <div class="head-icon-container">
                    <img src="../assets/Serendipia.svg" />
                  </div>
                </div>
                <div class="head-content col-10 d-flex align-items-center">
                  <span class="row autor"
                    >{{ mainNote.autorS }} · Serendipia</span
                  >
                </div>
              </div>
              <div class="card-head row">
                <div class="head-icon col-2">
                  <div class="head-icon-container">
                    <img src="../assets/Poder.svg" />
                  </div>
                </div>
                <div class="head-content col-10 d-flex align-items-center">
                  <span class="row autor">{{ mainNote.autorP }} · PODER</span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <!-- others -->
      <div class="row pb-md-5 mb-md-5" v-for="note in notes" :key="note.id">
        <div class="col-md-5 cardComponent">
          <Card :info="note.serendipia" source="Serendipia"></Card>
        </div>
        <div class="col-md-5 offset-md-2 cardComponent">
          <Card :info="note.poder" source="PODER"></Card>
        </div>
      </div>
    </div>
    <!-- texto final -->
    <div class="container-fluid textFinal">
      <div class="row">
        <h3>Sobre el proyecto</h3>
        <p align="left">
          Víctimas Paralelas es una iniciativa del Proyecto sobre Organización,
          Desarrollo, Educación e Investigación (PODER Latam) y Serendipia que
          contempla investigaciones periodísticas en al menos cinco estados de
          la República Mexicana, análisis de las contrataciones públicas en todo
          el país y su respectiva liberación para consulta de la audiencia de
          ambos medios, así como alianzas con otras organizaciones de la
          sociedad civil y 23 publicaciones finales.
        </p>
        <p>
          <strong>Dirección de Proyecto:</strong> Alejandra Padilla y Fernanda
          Hopenhaym.<br />
          <strong>Coordinación Periodística:</strong> Ricardo Balderas y
          Alejandra Padilla.<br />
          <strong>Edición:</strong> Elena Arengo (PODER) y Alejandra Padilla
          (Serendipia). <br />
          <strong>Trabajo periodístico:</strong> Antxa Arcos (PODER), Ricardo
          Balderas (PODER), Karen Flores (Serendipia) y Flor Guillén
          (Serendipia).
          <br />
          <strong>Análisis de datos:</strong> Queletzú Aspra (PODER) y Marco
          Antonio Trujano (Serendipia) <br />
          <strong>Diseño gráfico y desarrollo del front-end:</strong> Paulina
          Soto y Carolina López Tello (Serendipia). <br />
          <strong>Ilustración:</strong> Paulina Soto (Serendipia), Carolina
          López Tello (Serendipia) y Dani Scharf (PODER).
        </p>
        <p>
          Este proyecto no habría sido posible sin la cooperación de todas las
          personas integrantes de Serendipia y PODER. También agradecemos a
          Aristegui Noticias, Pie de Página, Cero Desabasto, Zona Docs, revista
          Proceso, Animal Político, Gatopardo, La Jornada Veracruz, Fundación
          tócate (Mérida, Yucatán) y La Crónica de Xalapa por su constante apoyo
          en el proyecto y la difusión del mismo.
        </p>
      </div>
    </div>
    <!-- fecha de actulización -->
    <div class="row mt-5">
      <p class="text-center">
        Fecha de actualización: {{ lastUpdate.fecha_actualizacion }}
      </p>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import mainnote from "../mainnote.json";
import notas from "../notas.json";
import update from "../update.json";
import Card from "../components/card.vue";
export default {
  setup() {
    const notes = reactive(notas.reverse());
    const mainNote = reactive(mainnote);
    const updateList = reactive(update);
    const lastUpdate = updateList[updateList.length - 1];
    return {
      mainNote,
      notes,
      lastUpdate,
    };
  },
  name: "AcercaDe",
  components: {
    Card,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
mark {
  font-weight: bolder;
  background-color: transparent;
}
.mainNote a {
  text-decoration: none;
}
.mainNote .mainImage {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.textCol {
  border: 1px solid #ccc;
  border-radius: 0px 10px 10px 0px;
  color: #4e4e4e;
}
.imgCol:hover + .textCol {
  background-color: #e2dddd;
}
.textCol:hover {
  background-color: #e2dddd;
}
.mainNote {
  transition: transform 0.3s;
}
.mainNote:hover {
  transform: scale(1.05);
}
.mainNote .imgCol img {
  width: 100%;
}
.mainNote .title {
  font-size: 1.5rem;
  color: #4e4e4e;
  font-weight: bolder;
}
.mainNote .date {
  color: #858585;
}
.head-icon-container {
  width: 40px;
}
@media (max-width: 767px) {
  .cardComponent {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  h1 {
    font-size: 32px;
  }
  .head-icon-container {
    width: 30px;
  }
  .cardComponent {
    margin-bottom: 10px;
  }
  .textCol {
    border: 1px solid #ccc;
    border-radius: 0px 0px 10px 10px;
  }
}
</style>